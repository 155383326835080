/**
 *
 * App.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 */

import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import ErrorBoundary from '../components/ErrorBoundary';
import NotFoundPage from '../containers/NotFoundPage/Loadable';
import { routes, routesRedirects } from './routes';

export default function App() {
  // Temporary hack for broken url
  if (window.location.pathname.startsWith('/start%7Bignore%7D')) {
    window.location.href = `/start${window.location.search}`;
  }
  return (
    <BrowserRouter>
      <ErrorBoundary>
        <Switch>
          {Object.values(routes).map(({ path, component, props }) => (
            <Route key={path} {...{ component, path, ...props }} />
          ))}
          {Object.values(routesRedirects).map(({ from, to }) => (
            <Redirect exact from={from} to={to} key={from + to} />
          ))}
          <Route exact path="/service-worker.js" onEnter={() => window.location.reload()} />
          <Route exact path="/manifest.json" onEnter={() => window.location.reload()} />
          <Route exact path="/sitemap.xml" onEnter={() => window.location.reload()} />
          <Route component={NotFoundPage} />
        </Switch>
      </ErrorBoundary>
    </BrowserRouter>
  );
}
